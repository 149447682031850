@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-BlackIt.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-BlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Bold.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Black.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Heavy.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-BoldItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-HeavyIt.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-HeavyIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Light.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Italic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-LightIt.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-ThinItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Thin.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-UltLightIt.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-UltLightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Regular.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-Medium.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-MediumIt.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-MediumIt.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro';
    src: url('/fonts/NeueHaasUnicaPro-UltraLight.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro XBlack';
    src: url('/fonts/NeueHaasUnicaPro-XBlackIt.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-XBlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica Pro XBlack';
    src: url('/fonts/NeueHaasUnicaPro-XBlack.woff2') format('woff2'),
    url('/fonts/NeueHaasUnicaPro-XBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Victor Serif';
    src: url('/fonts/VictorSerif-Regular.woff2') format('woff2'),
    url('/fonts/VictorSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Victor Serif';
    src: url('/fonts/VictorSerif-RegularItalic.woff2') format('woff2'),
    url('/fonts/VictorSerif-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


